@import "core/index.scss";
.page.product-comparison {
	padding: 4rem 0;

	@include media {
		padding: 0 0 4rem;
	}

	.comparison-wrap {
		@include media {
			max-width: 100%;
		}
	}

	.comparison-container {
		border: .1rem solid $color-gray-light;
		display: flex;
		justify-content: flex-start;
		
		&:not(.empty) {
			align-items: flex-start;
		}

		&.empty {
			.comparison-productcontainer{
				border-bottom: .1rem solid $color-gray-light;
			}
		}

		@include media {
			display: block;
		}
	}

	.comparison-legend {
		padding: 2.6rem 2.6rem .1rem;
		border-right: .1rem solid $color-gray-light;
		width: 23rem;
	}

		.legend-header {
			padding-left: 2.7rem;
			position: relative;
			height: 9rem;

			.header-icon {
				position: absolute;
				left: 0;
				top: .2rem;
				font-size: 1.8rem;
			}
	
			.header-title {
				@include font-bold;
				display: block;
				font-size: 1.8rem;
				line-height: 1.3em;
			}
	
			.header-count {
				@include font-medium;
				display: block;
				color: $color-text-blue;
				margin-top: 1rem;
				font-size: 1.3rem;
			}
		}

		.legend-slot {
			@include font-semibold;
			display: block;
			color: $color-text-dark;
			// border-bottom: .1rem solid $color-gray-light;

			&.info {
				height: 19.5rem;
			}

			&.rating {
				height: 8rem;
				padding: 2rem 0 0;
			}

			&.property {
				padding-top: .2rem;
				height: 4rem;
				display: flex;
				align-items: center;
			}
		}

	.comparison-content {
		width: calc(100% - 23rem);
		// overflow-y: hidden;
		overflow: hidden;

		@include media {
			width: 100%;
		}
	}

		.content-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			padding: 2.5rem 4rem;
			border-bottom: .1rem solid $color-gray-light;

			@include media {
				padding: 2.5rem 2rem;
			}

			.header-title {
				font-size: 2.4rem;
	
			}
			
				.title-count {
					@include font-medium;
					display: block;
					font-size: 1.3rem;
					color: $color-text-blue;
				}
		}

			.header-scrollnav {
				display: flex;
			}

				.scrollnav-btn {
					font-size: 2rem;
					width: 3rem;
					height: 3rem;
					opacity: .9;
					transition: opacity $transition-hover ease;

					&:disabled {
						opacity: .3;
					}

					@include mediaMin {
						&:hover {
							opacity: 1;
						}
					}

					+ .scrollnav-btn {
						margin-left: 1rem;
					}
				}


		.comparison-productcontainer {
			overflow-y: hidden;
			// height: auto;
			// padding-bottom: 2rem;
			position: relative;
			width: calc(100% + .1rem);
			margin-right: -.1rem;

			@include media {
				padding: 0 1rem;
			}
		}

		.comparison-productlist {
			// white-space: nowrap;
			width: 100%;
			overflow: visible;
		}

			.swiper-slide {
				width: 32.5rem;

				@include media {
					width: 20rem;
				}
			}

			.productlist-item {
				// display: inline-block;
				// width: 32.5rem;
				// margin-bottom: -.1rem;

				// @include media {
				// 	width: 31rem;
				// }

				@include mediaMin {
					&:last-child:nth-child(n-3) {
						border-right: .1rem solid $color-gray-light;
					}
				}

				+ .productlist-item {
					border-left: .1rem solid $color-gray-light;
				}
			}

				.item-info {
					display: flex;
					flex-flow: row wrap;
					padding: 3rem 2rem 2rem 4rem;
					white-space: initial;
					height: 23rem;
					border-bottom: .1rem solid $color-gray-light;

					@include media {
						padding: 2rem 1rem 3rem;
						height: 41rem;
						flex-direction: column;
					}
				}

					.info-image {
						width: 8.7rem;
						height: 8.7rem;
						border: .1rem solid $color-gray-light;
						margin-right: 1.5rem;

						@include media {
							width: 100%;
							padding-bottom: 100%;
							margin-right: 0;
							margin-bottom: 1.5rem;
						}
					}

					.info-text {
						flex: 1 0 0;
					}

					.info-name {
						@include font-semibold;
						font-size: 1.3rem;
						line-height: 1.5rem;
						height: 4.5rem;
					}

					.info-price {
						margin-top: 1.5rem;
						height: 4rem;
					}

					.info-controls {
						width: 100%;
						margin-top: 2rem;
						display: flex;
						justify-content: space-between;
						align-items: center;
					}

				.item-rating {
					padding: 2rem 2rem 0 4rem;
					height: 8rem;
					border-bottom: .1rem solid $color-gray-light;

					@include media {
						padding-left: 1rem;
					}
				}

					.rating-title {
						display: block;
						color: $color-text-light;
						margin-bottom: .5rem;
					}

				// .item-properties {}

					.properties-row {
						white-space: nowrap;
						height: 4rem;
						display: flex;
						align-items: center;
						padding: 0 2rem 0 4rem;
						color: $color-text-lighter;

						@include media {
							padding: .8rem 0 .8rem 1rem;
							height: auto;
							font-size: 1.4rem;
							display: block;
						}

						+ .properties-row {
							border-top: .1rem solid $color-gray-light;
						}

						&:last-child {
							// border-bottom: .1rem solid $color-gray-light;
							@include mediaMin {
								height: 4.1rem;
							}
						}
					}

						.row-title {
							display: block;
							color: $color-text-light;
							margin-bottom: .5rem;
							font-size: 1.2rem;
						}
}